//
// overriding a few bootstrap variables

$grid-gutter-width: 1em;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

$theme: #3b3d3d;
$orange: #f26643;

//
// imports

@import "node_modules/bootstrap/scss/bootstrap";
@import "_mixins.scss";
@import "_fonts.scss";

//
// animated sections

@keyframes animate-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-item {
  opacity: 0;
  transform: translateY(-10px);
}

.animated .animated-item {
  animation: animate-in 0.4s ease-out;
  animation-fill-mode: both;

  &.animate-order-1 {
    animation-delay: 0;
  }

  &.animate-order-2 {
    animation-delay: 0.2s;
  }

  &.animate-order-3 {
    animation-delay: 0.4s;
  }

  &.animate-order-4 {
    animation-delay: 0.6s;
  }

  &.animate-order-5 {
    animation-delay: 0.8s;
  }

  &.animate-order-6 {
    animation-delay: 1s;
  }
}

//
// global style rules incl. form controls, buttons, vimeo embed

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.33;
  font-family: "Proxima Nova", sans-serif;
  color: $theme;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    font-size: 1.6vw;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.44em;
  }
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.125;
  margin-bottom: 0;
}

.bold {
  font-weight: 600;
}

.form-control,
.form-control:focus,
.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

form {
  position: relative;

  .sc-form-container {
    .form-group-select {
      position: relative;

      .sc-select {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

    .form-group {
      margin-bottom: 1em;

      .form-control {
        height: 2.875em;
        padding: 0 0.75em;
        font-size: 0.875em;
        font-weight: 300;
        line-height: 1;
        // color: #fff;
        color: $theme;
        background-color: transparent;
        background-position: 100% 50% !important;
        border-style: solid;
        border-color: $theme;
        border-radius: 0;
        border-width: 0.05em;

        &::placeholder {
          color: $theme;
        }
      }

      textarea.form-control {
        height: 7.5em;
      }
    }

    .form-toggle {
      color: $theme;
      line-height: 2.5em;

      .form-toggle-label {
        line-height: 1.325;
        text-align: center;
        display: inline-block;
        margin-right: 0.25em;
      }

      .form-toggle-option {
        display: inline-block;
        margin: 0 0.25em;
        font-weight: 400;
        line-height: 1.325;
        text-align: center;
        opacity: 0.5;
        transition: color ease 200ms;
        cursor: pointer;
      }

      .form-toggle-input[value="yes"] ~ .sc-yes,
      .form-toggle-input[value="no"] ~ .sc-no {
        opacity: 1;
      }
    }

    .form-check {
      position: relative;

      .form-check-input {
        appearance: none;
        position: absolute;
        border: 1px solid $theme;
        outline: none;
        width: 1em;
        height: 1em;

        &:checked::before {
          content: "";
          position: absolute;
          width: 30%;
          height: 50%;
          transform: translate(-50%, -65%) rotate(45deg);
          border: solid $theme;
          border-width: 0 0.1em 0.1em 0;
          left: 50%;
          top: 50%;
        }
      }

      .form-check-label {
        line-height: 1.4;
        color: $theme;

        @include media-breakpoint-down(md) {
          font-size: 0.9em;
          margin: 0.125em 0 0 1.5em;
        }

        @include media-breakpoint-up(lg) {
          font-size: 0.625em;
          margin: 0.325em 0 0 1.25em;
        }
      }
    }
  }

  .sc-form-error-container,
  .sc-form-success-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 2em;
    visibility: hidden;
    pointer-events: none;
    font-size: 1.5em;
    line-height: 1.25;
  }

  &.success,
  &.error {
    .sc-form-container {
      visibility: hidden;
    }
  }

  &.success .sc-form-success-container,
  &.error .sc-form-error-container {
    visibility: visible !important;
  }

  select {
    background: url(../img/arrow_down.png) no-repeat;
    background-size: 60px;
    background-position: right center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.svg-defs {
  display: none;
}

.sc-img {
  display: block;
  width: 100%;
}

.sc-svg {
  display: block;
  width: 100%;
  fill: #000;
}

.sc-heading {
  font-family: Kaftan Serif Regular;
  margin-bottom: 1.125em;
  font-size: 2.125em;
  font-weight: 300;
  text-transform: uppercase;
}

.sc-subheading {
  font-family: Kaftan Serif Regular;
  letter-spacing: 0.025em;
  margin-bottom: 1.25em;
  font-size: 1.25em;
  font-weight: 300;
  line-height: 1.1;
  color: #fff;
  text-transform: uppercase;
}

.heading {
  font-family: Kaftan Serif Regular;
  letter-spacing: 0.01em;
}

.sc-copy {
  margin-bottom: 2.125em;
  font-weight: 300;
  line-height: 1.7;
}

.sc-btn-row {
  text-align: center;
}

.sc-cta,
.sc-btn {
  display: inline-block;
  margin: 0 auto;
  padding: 0.675em 1em 0.5em 1em;
  background-color: #db5c40;
  color: #fff;
  font-size: 0.675em;
  font-weight: 600;
  letter-spacing: 0.25em;
}

//
// header, sections, footer

.fixed-btn {
  position: fixed;
  right: 3em;
  top: 1.75%;
  @include media-breakpoint-up(lg) {
    top: 1.75em;
  }
  .horizontal {
    display: inline-block;
  }
  .vertical {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    .horizontal {
      display: none;
    }
    .vertical {
      display: inline-block;
    }
    top: 17%;
    right: 0;
    a {
      font-size: 0.47em;
    }
  }
}

.select {
  min-height: 100%;

  .sc-select {
    position: relative;
    display: flex;
    min-height: 100vh;
    color: #fff;
    text-align: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .sc-left,
    .sc-right {
      min-height: 100%;
      padding: 3em 0;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      .sc-logo {
        width: 9em;
        margin-bottom: 3em;
      }

      .sc-img {
        width: 60%;
        display: block;
        margin: 0 auto 2em auto;
      }

      .sc-copy-2 {
        font-size: 0.75em;
      }

      .sc-cta {
        margin-top: 2em;
      }
    }

    .sc-left {
      background-color: #d35e44;

      .sc-cta {
        background-color: #0a3c7b;
      }
    }

    .sc-right {
      background-color: #3b3d3d;
    }
  }
  .sc-middle {
    .link-overlay {
      position: absolute;
      height: 200px;
      width: 50%;
      bottom: 0px;
      &.canderal {
        left: 0px;
      }
      &.kingsett {
        right: 0px;
      }
    }
    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      overflow: hidden;
      background-color: #0a3c7b;
      position: absolute;
      top: 10.4em;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;
      height: 18.75em;
    }

    sc-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .select-copyright {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 10px;
    .copy {
      display: inline-block;
    }
    .logo-rad {
      width: 5em;
      fill: #fff;
      display: inline-block;
    }
  }
}

.sc-navbar {
  padding: 0;
  background-color: #3b3d3d;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    height: 4em;
  }

  @include media-breakpoint-up(lg) {
    height: 5.25em;
  }

  .sc-svg {
    fill: #fff;
  }

  .navbar-brand {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    height: 200%;
    transition: transform 200ms ease;

    @include media-breakpoint-down(md) {
      width: 6em;
    }

    @include media-breakpoint-up(lg) {
      width: 12em;
    }

    .sc-svg {
      display: block;
      width: 100%;
      height: 50%;

      &.sc-logo-908-st-clair {
        width: 66%;
        margin: 0 auto;
      }
    }
  }

  &.sc-shown .navbar-brand {
    transform: translateY(-50%);
  }
  // .sc-hb {
  // 	position: absolute;
  // 	left: 20px;
  // 	width: 20px;
  // 	height: 20px;
  // 	cursor: pointer;

  // 	span {
  // 		position: absolute;
  // 		width: 100%;
  // 		height: 2px;
  // 		background-color: #fff;

  // 		&:nth-child(2) {
  // 			top: 8px;
  // 		}

  // 		&:nth-child(3) {
  // 			top: 16px;
  // 		}
  // 	}
  // }

  .sc-logo-canderel-kingsett {
    a.canderal {
      width: 31%;
      padding-right: 20px;
    }
    a.kingset {
      width: 48%;
      padding-left: 20px;
      border-left: 1px solid #fff;
    }
    img {
      width: 100%;
    }
    a {
      display: inline-block;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      right: 2em;
      width: 12em;
      height: 100%;
    }
  }

  .navbar-toggler {
    position: absolute;
    top: 1.5em;
    left: 1.5em;
    @include media-breakpoint-down(md) {
      top: 0.65em;
      left: 1em;
    }
    @include media-breakpoint-up(lg) {
      top: 2.15em;
      left: 2em;
    }

    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
  }

  .navbar-toggler[aria-expanded*="false"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    transition: background-image 0.25s ease-out;
  }

  .navbar-toggler[aria-expanded*="true"] {
    // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAsklEQVRIie2WSwqAMAxER+9g0fsfR1e68Di6aUHFTyYdKKgDWbXjSyNNA/z6qgKAIUbI8PeMvwEwAlhizAA6Aur2DxtTigm2zEPce/T3XrAFfgU1g4+l2sZV2Z48rQXMwmVQBi6HJt39t+lhzXMNd7o7lfykXrgUaoVT0FqcXCX+HlVqpr1KoFJ4ketkaQ6e9poNte41wz1tUAIv9iwWGwTORhemDWb5Q8ySGtaE/l8v0ArSo+eVYaS+0wAAAABJRU5ErkJggg==");
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAwElEQVRIie3WMQ6DMAyFYdo7FLX3v0alDpU6pJ2ageP8HcgQRTTC9kMsvAkJ448gYmUYjuwR4AEkYBT2HIEP8OoVJeZkBV7QXHqmXuEF+JbCCbgF0LbX1fqAGTejCtyNRvAw6sFlqAWXo2vwzdAevjla4fVAyM21bNr9w+tVuld6Fr3PSdRnObt86qUfyTNkwmjnngZfs2XkuGWfynDPcAjjkYnkxiOoG1egLpz5NAg7HPaewFuBNngC7qqeR0z5AY0M7m07N6ZiAAAAAElFTkSuQmCC");

    transition-duration: 0.25s;
  }

  .navbar-collapse {
    position: fixed;
    // top: 54px;
    top: 5.25em;
    @include media-breakpoint-down(md) {
      top: 4em;
    }
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    min-width: 350px;
    // background-color: $theme;
    background-color: $orange;
    background-image: url("../img/bg-orange.png");
    background-size: 3.375em;

    .nav-link {
      font-weight: bold;
      padding: 1.15rem 2.5rem;
      &:hover {
        background-color: $theme;
      }
    }
    .nav-item {
      .sc-social {
        display: flex;
        margin-top: 7vh;
        margin-left: 2.5em;
        margin-bottom: 2.5em;

        .sc-icon .sc-svg {
          fill: #fff;
          width: auto;
          height: 1em;
          margin-right: 1em;
        }
      }
    }
  }

  .navbar-collapse.collapsing {
    height: 100%;
    left: -75%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    height: 100%;
    left: 0;
    transition: left 400ms ease-in-out;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 400ms ease-in;
  }
}

main {
  max-width: 62.5em;
  margin: 0 auto;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 0;
  }
}

.sc-hero {
  .sc-logo-908-st-clair-hero {
    fill: #fff;
    position: absolute;
    top: 35%;
    left: 35%;
    width: 30%;
  }
  @include media-breakpoint-down(md) {
    margin-top: 4em !important;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 5.25em !important;
  }

  .sc-bg {
    position: relative;
    h1 {
      padding-top: 3em;
      padding-bottom: 3em;
      text-align: center;
      color: #fff;
    }
    &.neighbourhood {
      .heading {
        @include media-breakpoint-down(md) {
          font-size: 2.15rem;
        }
      }
      background-color: $theme;
      background-image: url(../img/dark.png);
      background-size: 4.375em;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background-color: #db5c40;

        @include media-breakpoint-down(md) {
          width: 1em;
        }

        @include media-breakpoint-up(lg) {
          width: 1.75em;
        }
      }
    }

    &.transit {
      background-color: $orange;
      background-image: url("../img/bg-orange.png");
      background-size: 4.375em;
    }

    &.builder {
      .heading {
        padding-top: 18vw;
      }
      min-height: 70vw;

      background-image: url("../img/builder/hero.jpg");
      background-size: cover;
      background-position: center;
      @include media-breakpoint-up(xxl) {
        min-height: 800px;
      }
    }
  }
}

.sc-sophisticated-st-clair,
.sc-video,
.sc-sub-copy {
  margin-top: 3.75em;
  margin-bottom: 1.75em;
}
.sc-sub-copy {
  // h3 {
  //   font-family: Kaftan Serif Regular;
  // }
}
.sc-lifestyle {
  margin-top: 3.75em;

  display: flex;
  background-color: #867f79;
  background-image: url("../img/bg-light-gray.png");
  background-size: 4.375em;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
  }

  .sc-img-1 {
    @include media-breakpoint-up(md) {
      width: 50%;
    }

    // @include media-breakpoint-up(lg) {
    //   width: 50%;
    // }
  }
  .sc-container-1 {
    @include media-breakpoint-up(md) {
      width: 50%;
    }

    // @include media-breakpoint-up(lg) {
    //   width: 50%;
    // }

    .sc-img-2 {
      width: 64%;

      @include media-breakpoint-down(md) {
        margin-right: auto;
      }

      @include media-breakpoint-up(lg) {
        margin-left: auto;
      }
    }

    .sc-subheading {
      @include media-breakpoint-down(md) {
        margin: 2em;
      }

      @include media-breakpoint-up(lg) {
        text-align: right;
        margin: 4em 2em 0 7em;
      }
    }
  }
}

.sc-streetcar {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #3b3d3d;
  background-image: url("../img/bg-gray.png");
  background-size: 4.375em;

  @include media-breakpoint-down(md) {
    background-position: 0 -3em;
    flex-direction: column;
  }

  @include media-breakpoint-up(lg) {
    background-position: 0 -0.675em;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #db5c40;

    @include media-breakpoint-down(md) {
      width: 1em;
    }

    @include media-breakpoint-up(lg) {
      width: 1.75em;
    }
  }

  .sc-container-1 {
    width: 100%;

    .sc-subheading {
      line-height: 1.4;

      @include media-breakpoint-down(md) {
        margin: 2em;
      }

      @include media-breakpoint-up(lg) {
        margin: 2.75em 54% 2.75em 2.75em;
      }
    }
  }

  .sc-img-1 {
    @include media-breakpoint-down(md) {
      width: 60%;
    }

    @include media-breakpoint-up(lg) {
      width: 39%;
    }
  }

  .sc-container-2 {
    @include media-breakpoint-down(md) {
    }

    @include media-breakpoint-up(lg) {
      width: 61%;
    }

    .sc-copy {
      color: #fff;

      @include media-breakpoint-down(md) {
        margin: 2em 3em 2em 2em;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 6em 0 7em;
      }
    }
  }
}

.sc-map {
  display: flex;
  background-color: #db5c40;
  background-image: url("../img/bg-orange.png");
  background-size: 4.375em;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    background-position: 0 -1.5em;
  }

  .sc-container-1 {
    @include media-breakpoint-up(lg) {
      width: 39%;
    }

    .sc-img-1 {
      width: 65%;
      margin-left: auto;
      @include media-breakpoint-down(md) {
        margin-left: unset;
        width: 50%;
        display: inline-block;
      }
    }

    .sc-subheading {
      text-align: right;
      line-height: 1.4;

      @include media-breakpoint-down(md) {
        margin: 2em;
      }
      @include media-breakpoint-down(sm) {
        margin: 1em 1em 1em 1em;
      }
      @include media-breakpoint-down(md) {
        display: inline-block;
        width: 40%;
      }

      @include media-breakpoint-up(lg) {
        margin: 3.5em 2.5em 0 5.25em;
      }
    }
  }

  .sc-img-2 {
    @include media-breakpoint-up(lg) {
      width: 61%;
    }
  }
}

.sc-register {
  // background-color: #867f79;
  padding-top: 2.75em;

  .sc-heading {
    font-size: 2em;
    // color: #fff;
    margin-bottom: 0.875em;
  }

  #sc-register-form {
    position: relative;

    @include media-breakpoint-down(md) {
      margin: 0 2em;
    }

    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      // -webkit-text-fill-color: #fff;
      // box-shadow: 0 0 0px 1000px #867f79 inset;
      // -webkit-box-shadow: 0 0 0px 1000px #867f79 inset;

      -webkit-text-fill-color: $theme;
      box-shadow: 0 0 0px 1000px #fff inset;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;

      transition: background-color 5000s ease-in-out 0s;
    }

    .sc-form-error-container,
    .sc-form-success-container {
      position: absolute;
      width: 100%;
      left: 0;
      top: 5em;
      text-align: center;
      visibility: hidden;
      pointer-events: none;
      font-size: 1em;
      font-weight: 300;
      line-height: 1.25;
      color: $theme;
    }

    &.sc-success,
    &.sc-error {
      .sc-form-container {
        visibility: hidden;
      }
    }

    &.sc-success .sc-form-success-container,
    &.sc-error .sc-form-error-container {
      visibility: visible !important;
    }

    div[data-section="future"],
    div[data-section="coming-soon"] {
      display: none;
    }

    .sc-phone-col {
      padding-right: 0.5em;
    }

    .sc-email-col {
      padding-left: 0.5em;
    }

    .sc-how-col {
      padding-right: 0.5em;
    }

    .sc-is-broker-col {
      padding-left: 0.5em;
    }
    .sc-btn-submit {
      border-radius: 0;
    }
  }

  .sc-map-col {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      height: 35em;
    }

    .sc-map {
      height: 100%;
      //object-fit: cover;
    }

    .form-group {
      margin-top: 1em;
      display: flex;
    }
  }
}

.sc-canderel {
  margin-top: 5.5em;
  margin-bottom: 5.125em;

  .sc-heading {
    // font-size: 2em;
    margin-bottom: 0.325em;
  }
}

footer {
  background-color: #3b3d3d;
  padding-top: 2.625em;
  padding-bottom: 2.625em;
  .sc-address {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  .sc-logo-st-clair {
    fill: #d45e44;
    width: 10em;
    @include media-breakpoint-down(sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .sc-social {
    display: flex;
    margin-top: 2em;
    margin-left: 3.5em;
    margin-bottom: 2.5em;

    .sc-icon .sc-svg {
      fill: #fff;
      width: auto;
      height: 1em;
      margin-right: 1em;
    }
    @include media-breakpoint-down(sm) {
      margin-left: calc(50% - 16px);
    }
  }

  .sc-directions {
    margin-top: 1.125em;
    display: flex;

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      display: inline-block;
      .sc-link img {
        margin-bottom: 1rem;
      }
    }
    .sc-link img {
      margin-right: 1em;

      @include media-breakpoint-down(md) {
        height: 1.75em;
      }

      @include media-breakpoint-up(lg) {
        height: 2em;
      }
    }
  }

  .sc-copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.125em;

    @include media-breakpoint-up(md) {
      margin-top: 5.25em;
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .sc-logo-rad {
      flex-grow: 0;
      fill: #fff;
      width: 2.5em;
      margin-right: 1em;
      @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
      }
    }

    .sc-copy {
      flex-grow: 1;
      color: #fff;
      font-size: 0.53em;
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .sc-logo-canderel-kingsett {
      flex-grow: 0;
      fill: #fff;
      width: 12.5em;

      @include media-breakpoint-down(sm) {
        margin: auto;
        text-align: center;
      }

      a.canderal {
        width: 31%;
        padding-right: 20px;
      }
      a.kingset {
        width: 48%;
        padding-left: 20px;
        border-left: 1px solid #fff;
      }
      img {
        width: 100%;
      }
      a {
        display: inline-block;
      }
    }
  }
}

.min-section-gap {
  section {
    margin-top: 3em;
    margin-bottom: 3em;
  }
}
.min-section-gap-2 {
  section {
    padding-top: 3em;
    padding-bottom: 3em;
  }
  .sc-hero {
    padding: 0;
  }
}

.sc-features-intro {
  h1 {
    font-size: 2.25em;
    margin-bottom: 3rem;
  }
  p {
    padding-left: 1rem;
    padding-right: 1rem;
    @include media-breakpoint-up(lg) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
}
.sc-features {
  h1 {
    font-size: 2.15em;
    margin-bottom: 2rem;
    &.sc-time {
      font-size: 3em;
    }
  }
  .feat-img {
    @include media-breakpoint-down(lg) {
      margin-bottom: 3em;
    }
  }
  p {
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    @include media-breakpoint-up(lg) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
}
.text-left-lg,
.text-right-lg,
.text-left-md,
.text-right-md {
  text-align: center;
}
@include media-breakpoint-up(lg) {
  .text-left-lg {
    text-align: left;
  }
  .text-right-lg {
    text-align: right;
  }
}
@include media-breakpoint-up(md) {
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
}

.light-grey-bg {
  background-color: $theme;
  h1,
  p {
    color: #fff !important;
  }
}

.s-light-grey-bg {
  background-color: #867f79;
  h1,
  p {
    color: #fff !important;
  }
}

.s-transit-bluish-grey-bg {
  background-color: #303b3c;

  h3,
  p {
    color: #fff !important;
  }
  h3 {
    margin-top: 0.5rem;
    text-transform: uppercase;
  }
}

.sc-bg-1 {
  background-image: url(/assets/img/neighbourhood/bg.jpg);
  background-position: left bottom;
  background-size: cover;
  padding: 0px !important;
  min-height: 60vw;

  .h-100-c {
    @include media-breakpoint-up(lg) {
      min-height: 52vw;
    }
  }
  @include media-breakpoint-up(xxl) {
    min-height: 856px;
    max-height: 856px;
    .h-100-c {
      min-height: 800px;
      max-height: 800px;
    }
  }
  @include media-breakpoint-down(md) {
    .align-items-end {
      padding-top: 20vw;
    }
  }
  position: relative;

  .container {
    h1,
    p {
      color: #fff !important;
    }
  }
}

.sc-bg-2 {
  background-image: url(/assets/img/transit/bg.jpg);
  background-position: left bottom;
  background-size: cover;
  @include media-breakpoint-down(xs) {
    background-size: 290%;
  }
  padding: 0px !important;
  min-height: 60vw;
  .h-100-c {
    @include media-breakpoint-up(lg) {
      min-height: 52vw;
    }
  }
  @include media-breakpoint-up(xxl) {
    min-height: 856px;
    max-height: 856px;
    .h-100-c {
      min-height: 800px;
      max-height: 800px;
    }
  }

  position: relative;

  .container {
    @include media-breakpoint-down(md) {
      padding-top: 40vw;
    }
    h1,
    p {
      color: #fff !important;
    }
  }
}

.sc-map-section {
  li {
    line-height: 1.75rem;
  }
  li,
  .heads {
    font-size: 1rem;
  }
  ul.no-marker {
    margin-top: 1.5rem;

    padding-inline-start: 0px;
    li::marker {
      content: "";
    }
    li {
      margin-bottom: 0.5rem;

      .icon {
        position: absolute;
        left: 1rem;
        margin-top: 0.5rem;
        height: 1rem;
        // width: 4rem;
        // width: 4rem;
      }
    }
    span {
      padding-left: 4rem;
    }
  }
  li::marker {
    font-weight: bold;
    color: $orange;
  }
  .heads {
    text-transform: uppercase;
    color: $orange;
    font-weight: bold;
    padding: 1rem 1rem 1rem 0rem;
  }
}

.orange {
  color: $orange;
}

.sc-tile {
}

.sc-floorplans {
  .embed-responsive.embed-responsive-1by1 {
    @include media-breakpoint-down(lg) {
      min-height: 100vh;
    }
  }
}

#gallery-modal {
  h1,
  h2 {
    font-family: Kaftan Serif Regular;
    line-height: 1.4;
    letter-spacing: 3px;
  }
  h2 {
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }
  h1 {
    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
    }
  }
  h1,
  h2,
  p {
    color: #867f79;
  }
  h1 {
    color: $orange;
  }
  .row {
    h2 {
      color: $orange;
      margin-bottom: 0.5rem;
    }
  }
  .sc-logo-908-st-clair {
    fill: #867f79;
    max-width: 200px;
    margin: auto;
    margin-bottom: 1.85rem;
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 1.75rem;
    img {
      width: 30px;
    }
    @include media-breakpoint-up(md) {
      right: 2rem;
      img {
        width: 35px;
      }
    }
  }
  .modal-header {
    border: none;
  }
  .modal-content {
    border-radius: 0px;
    border: none;
    background-color: #3b3d3d;
    background-image: url(../img/light-grey.png);
    background-size: 4.375em;
  }
  .modal-content::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 0.75em;
    background-color: #db5c40;
  }
}
